import React from 'react';
import axios from 'axios';
import Head from 'next/head';
import { NSContext, jwtManager } from 'aqlrc';
import Layout from '../components/Layout';
import Login from '../components/Login';
import getAPIUrl from '../lib/getAPIUrl';
import { withI18next } from '../lib/withI18n';
import { Router } from '../routes';

class Auth extends React.Component {
    static getInitialProps = async function (ctx) {
        const cmsLegalTxt = await axios.post(`${getAPIUrl(ctx)}v2/component/ns-cms/legalTxt`, { lang: 'fr' });

        return {
            cmsLegalTxt : cmsLegalTxt.data
        };
    };

    constructor(props) {
        super(props);
        this.state = {
            ...props,
            email        : '',
            login        : { email: '', password: '' },
            user         : { company: {} },
            creatingUser : false,
            gNext        : { Head, Router }
        };
    }

    componentDidMount = () => {
        const user = jwtManager.getUser();
        if (user) {
            Router.pushRoute('account');
        }
    }

    render() {
        const { oCmsHeader, oCmsFooter, sitename } = this.props;
        return (
            <NSContext.Provider value={{ props: this.props, state: this.state, onLangChange: (l) => this.onLangChange(l) }}>
                <Layout bodyClass="body body--mon-compte" header={oCmsHeader.content} footer={oCmsFooter.content}>
                    <Head>
                        <title>{sitename} | Authentification</title>
                        <link rel="stylesheet" href="/static/css/login-register.css" />
                    </Head>
                    <Login {...this.state} />
                </Layout>
            </NSContext.Provider>
        );
    }
}

export default withI18next(['login'])(Auth);
