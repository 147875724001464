import React from 'react';
import PhoneInput from 'react-phone-input-2'
import { NSLogin } from 'aqlrc';

class Login extends NSLogin {
    render() {
        const {
            emailResetPassword, step, user, address, countries, subUser, subscribeNL
        } = this.state;
        return (
            <main className="main">
                <div className="content">
                    <div className="container">
                        <div className="row">
                            <div className="content__body">
                                <div className="form form--compte-ok">
                                    <div className="form__icon">
                                        <i className="icon-mon-compte-form" />
                                    </div>
                                    <h1 className="form__heading">CONNEXION / INSCRIPTION</h1>
                                    {
                                        step !== 2 && (
                                            <div className="form-group form-group--type">
                                                <div className="custom-control custom-control-lg custom-radio">
                                                    <input type="radio" id="customRadio1" name="customRadio" className="custom-control-input" defaultChecked onClick={() => this.setState({ step: 0 })} />
                                                    <label className="custom-control-label" htmlFor="customRadio1">Je possède un compte Suki Sushi</label>
                                                </div>
                                                <div className="custom-control custom-control-lg custom-radio">
                                                    <input type="radio" id="customRadio2" name="customRadio" className="custom-control-input" onClick={() => this.setState({ step: 1 })} />
                                                    <label className="custom-control-label" htmlFor="customRadio2">Non, je suis un nouveau client</label>
                                                </div>
                                            </div>
                                        )
                                    }
                                    {
                                        /* Connexion */
                                        step === 0 && (
                                            <form onSubmit={(e) => this.handleLoginSubmit(e)}>
                                                <div className="form-group">
                                                    <input type="email" className="form-control" id="email_login" value={user.email} placeholder="Adresse email" />
                                                </div>
                                                <input type="password" className="form-control" name="field-password" id="password_login" value={user.password} placeholder="Mot de passe" />
                                                <div className="row text-muted">
                                                    <div className="col">
                                                        <div className="form-group form__forgotpw">
                                                            <button type="button" className="btn btn-link" onClick={() => this.setState({ step: 2 })}>Mot de passe oublié</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group form__footer">
                                                    <button type="submit" className="btn btn-primary">Se connecter</button>
                                                </div>
                                            </form>
                                        )
                                    }
                                    {
                                        /* Inscription */
                                        step === 1 && (
                                            <form onSubmit={(e) => this.handleRegisterSubmit(e)}>
                                                <div className="form__body">
                                                    <div className="form-group">
                                                        <div className="flex-checkbox">
                                                            <div className="custom-control custom-control-lg custom-radio">
                                                                <input hidden type="radio" name="civility" id="field-women" className="custom-control-input" value={1} onChange={(e) => this.handleChangeSub(e)} />
                                                                <label className="custom-control-label" htmlFor="field-women">Madame</label>
                                                            </div>
                                                            <div className="custom-control custom-control-lg custom-radio">
                                                                <input hidden type="radio" name="civility" id="field-men" className="custom-control-input" value={0} onChange={(e) => this.handleChangeSub(e)} />
                                                                <label className="custom-control-label" htmlFor="field-men">Monsieur</label>
                                                            </div>
                                                            <span>*</span>
                                                        </div>
                                                    </div>{/* <!-- /.form-group --> */}

                                                    <div className="form-group">
                                                        <div className="form__controls">
                                                            <input required type="text" className="form-control" name="lastname" id="field-name" value={subUser.lastname} placeholder="Nom" onChange={(e) => this.handleChangeSub(e)} /><span>*</span>
                                                        </div>
                                                    </div>{/* <!-- /.form-group --> */}

                                                    <div className="form-group">
                                                        <div className="form__controls">
                                                            <input required type="text" className="form-control" name="firstname" id="field-name-last" value={subUser.firstname} placeholder="Prénom" onChange={(e) => this.handleChangeSub(e)} /><span>*</span>
                                                        </div>
                                                    </div>{/* <!-- /.form-group --> */}

                                                    <div className="form-group">
                                                        <div className="form__controls">
                                                            <input required type="text" className="form-control" name="address/line1" id="field-address" value={address.line1} placeholder="Adresse" onChange={(e) => this.handleChangeSub(e)} /><span>*</span>
                                                        </div>
                                                    </div>{/* <!-- /.form-group --> */}

                                                    <div className="form-group">
                                                        <div className="form__controls">
                                                            <input type="text" className="form-control" name="address/line2" id="field-address-2" value={address.line2} placeholder="Adresse (complément)" onChange={(e) => this.handleChangeSub(e)} />
                                                        </div>
                                                    </div>{/* <!-- /.form-group --> */}

                                                    <div className="form-group">
                                                        <div className="form__controls">
                                                            <input required type="text" className="form-control" name="address/zipcode" id="field-zip" value={address.zipcode} placeholder="Code postal" onChange={(e) => this.handleChangeSub(e)} /><span>*</span>
                                                        </div>
                                                    </div>{/* <!-- /.form-group --> */}
                                                    <div className="form-group">
                                                        <div className="form__controls">
                                                            <input required type="text" className="form-control" name="address/city" id="field-city" value={address.city} placeholder="Ville" onChange={(e) => this.handleChangeSub(e)} /><span>*</span>
                                                        </div>
                                                    </div>{/* <!-- /.form-group --> */ }

                                                    <div className="form-group">
                                                        <div className="form__controls">
                                                            <PhoneInput
                                                                country={'fr'}
                                                                inputClass="phone-input"
                                                                inputProps={{id: 'field-phone', name: 'address/phone_mobile', required: true, placeholder:"Téléphone portable"}}
                                                                inputStyle={{paddingLeft: '48px'}}
                                                                isValid={(inputNumber, _country, countries) => {
                                                                    return countries.some((country) => {
                                                                        return inputNumber.startsWith(country.dialCode) || country.dialCode.startsWith(inputNumber);
                                                                    });
                                                                }}
                                                                value={address.phone_mobile}
                                                                onChange={phone => this.handleChangeSub({target: {value: '+' + phone, name: 'address/phone_mobile'}})}
                                                            />
                                                            {/* <input required type="tel" className="form-control" name="address/phone_mobile" id="field-phone" value={address.phone_mobile} placeholder="Téléphone portable" onChange={(e) => this.handleChangeSub(e)} /><span>*</span> */}
                                                        </div>
                                                    </div>{/* <!-- /.form-group --> */}

                                                    <div className="form-group">
                                                        <div className="form__controls">
                                                            <select required type="tel" defaultValue="" className="form-control" name="address/isoCountryCode" id="field-country" value={address.isoCountryCode} onChange={(e) => this.handleChangeSub(e)}>
                                                                <option value="">- Pays -</option>
                                                                {
                                                                    countries.map((c) => (<option key={c._id} value={c.code}>{c.name}</option>))
                                                                }
                                                            </select>
                                                            <span>*</span>
                                                        </div>
                                                    </div>{/* <!-- /.form-group --> */}

                                                    <div className="form-group">
                                                        <div className="form__controls">
                                                            <input required type="email" className="form-control" name="email" id="field-email3" value={subUser.email} placeholder="Adresse email" onChange={(e) => this.handleChangeSub(e)} /><span>*</span>
                                                        </div>
                                                    </div>{/* <!-- /.form-group --> */}

                                                    <div className="form-group">
                                                        <div className="form__controls">
                                                            <input required type="email" className="form-control" name="confirmEmail" id="field-email-confirm" value={subUser.confirmEmail} placeholder="Adresse email (confirmation)" onChange={(e) => this.handleChangeSub(e)} /><span>*</span>
                                                        </div>
                                                    </div>{/* <!-- /.form-group --> */}

                                                    <div className="form-group">
                                                        <div className="form__controls">
                                                            <input required onChange={(e) => this.handleChangeSub(e)} type="password" className="form-control" name="password" id="field-password3" value={subUser.password} placeholder="Mot de passe" /><span>*</span>
                                                        </div>
                                                    </div>{/* <!-- /.form-group --> */}

                                                    <div className="form-group">
                                                        <div className="form__controls">
                                                            <input required onChange={(e) => this.handleChangeSub(e)} type="password" className="form-control" name="passwordConfirm" id="field-password-confirm3" value={subUser.passwordConfirm} placeholder="Mot de passe (confirmation)" /><span>*</span>
                                                        </div>
                                                    </div>{/* <!-- /.form-group --> */}

                                                    <div className="form-group">
                                                        <div className="custom-control custom-control-lg custom-checkbox">
                                                            <input type="checkbox" name="field-newsletter" className="custom-control-input" id="field-newsletter" checked={subscribeNL} value={subscribeNL} onChange={() => this.setState({ subscribeNL: !subscribeNL })} />
                                                            <label className="custom-control-label" htmlFor="field-newsletter">
                                                                    Je souhaite recevoir les offres commerciales
                                                            </label>
                                                        </div>
                                                    </div>{/* <!-- /.form-group --> */}

                                                    <div className="form-group form__footer">
                                                        <button type="submit" className="btn btn-primary">S'inscrire</button>
                                                    </div>
                                                    <div className="form__entry form__entry-spaced-right">
                                                        <div className="form__hint">
                                                            <p>*Champs obligatoires</p>
                                                        </div>
                                                    </div>
                                                </div>{/* <!-- /.form__body --> */ }
                                            </form>
                                        )
                                    }
                                    {
                                        /* Mot de passe oublié */
                                        step === 2 && (
                                            <form onSubmit={(e) => this.handleResetSubmit(e)}>
                                                <p>Mot de passe oublié ?<br />Entrez votre adresse email et nous enverrons un lien pour le réinitialiser (attention, le mail peut arriver dans vos spams) :</p>
                                                <div className="form-group">
                                                    <input type="email" className="form-control" name="field-email" id="email_login_forgot" value={emailResetPassword} placeholder="Adresse email" onChange={(e) => this.handleResetPassword(e)} />
                                                </div>
                                                <div className="form-group form__footer">
                                                    <button type="button" className="btn btn-primary" onClick={() => this.setState({ step: 0 })}>RETOUR</button> <button type="submit" className="btn btn-primary">ENVOYER</button>
                                                </div>
                                            </form>
                                        )
                                    }
                                </div>
                            </div>{/* <!-- /.CONTENT_BODY --> */}
                        </div>
                    </div>
                </div>{/* <!-- /.CONTENT --> */}
            </main>
        );
    }
}

export default Login;
